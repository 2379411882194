<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <span class="active">Buzón Institucional</span>
      </template>
      <template slot="area-titulo">
        <h4>Buzón Institucional</h4>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row">
        <div class="col-md-12">
            <p>Envíenos su documentación o correspondencia desde cualquier punto del país para la FISCALÍA GENERAL DEL ESTADO (NO VÁLIDO PARA PROCESOS PENALES)</p>      
        </div>  
        <div class="col-md-6 offset-lg-2">          
            <b-form @submit="onSubmit" @reset="onReset" v-if="show" validated >
                <b-form-group
                    id="input-group-remitente"
                    label="Nombre Remitente *"
                    label-for="nombre-remitente"
                    description=""
                    class="mb-3"
                >
                    <b-form-input
                    id="nombre-remitente"
                    v-model="form.nombre_remitente"
                    type="text"
                    placeholder="Escriba el nombre del remitente"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-correo"
                    label="Correo Electrónico *"
                    label-for="correo-electronico" 
                    class="mb-3"               
                >
                    <b-form-input
                    id="correo-electronico"
                    v-model="form.correo"
                    type="email"
                    placeholder="Escriba el correo"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group 
                    id="input-group-institucion" 
                    label="Institución" 
                    label-for="institucion"
                    class="mb-3"
                >
                    <b-form-input
                    id="institucion"
                    v-model="form.institucion"
                    placeholder="Escriba el nombre de la Institución"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-referencia" label="Referencia *" label-for="referencia" class="mb-3">
                    <b-form-textarea
                    id="referencia"
                    v-model="form.referencia"
                    placeholder="Escriba la referencia"
                    required
                    ></b-form-textarea>
                </b-form-group>

                <b-form-group id="input-group-adjunto" label="Adjuntar Archivo *" label-for="archivo" class="mb-3">
                    <b-form-file
                    id="archivo"
                    v-model="form.archivo"
                    placeholder="Elija un archivo o suéltelo aquí..."
                    plain
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ></b-form-file>                
                </b-form-group>
                
                <div class="mt-2">
                  <b-button type="submit" class="me-2 btn btn-warning btn-fiscalia">Enviar</b-button>
                  <b-button type="button" @click="limpiarForm" class="ms-2 btn btn-warning btn-fiscalia-white">Limpiar</b-button>
                </div>
            </b-form>
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 row">
        
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import TituloSeccion from "../secciones/TituloSeccion.vue";

export default {
  components: {
    TituloSeccion,    
  },
  data() {
    return {
        form: {
            nombre_remitente: '',
            correo: '',
            institucion: '',
            referencia: '',            
            archivo: null,
        },        
        show: true,
    };
  },
  metaInfo() {
    return {
      title: "Ministerio Público",
      htmlAttrs: {
        lang: "es-ES",
      },
      titleTemplate: "%s | " + 'Buzón Institucional',
      
    };
  },
  created() {
    
  },
  mounted() {
    
  },
  watch: {
    
  },
  beforeDestroy() {
    
  },
  methods: {
    onSubmit(event){
        event.preventDefault();
        //alert(JSON.stringify(this.form));
        var url= '/web/send-buzon-mail';
        axiosIns.get(url)
        .then(res => {            
            console.log(res.data);
            this.limpiarForm();
        })
        .catch(err =>{
            console.log(err);
        });
    },
    limpiarForm(){
      this.form.nombre_remitente = '';
      this.form.correo = '';
      this.form.institucion = '';
      this.form.referencia = '';
      this.form.archivo = '';
    }
  },
};
</script>

<style scoped>
  label{
    margin-bottom: 1rem !important;
  }
</style>
